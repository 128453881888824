"use client";

import { InteractionComponentType } from "@/hl-common/types/api/components/ComponentType";
import type { CardEntity } from "@/hl-common/types/api/entities/Cards";
import type { CourseEntity } from "@/hl-common/types/api/entities/Courses";
import type { EventEntityBase } from "@/hl-common/types/api/entities/Events";
import { getStorage } from "./localStorage";

// localStorage helpers
const lockoutKey = "lockout";
const rushingWindowKey = "rushingWindow";
const rushingTierKey = "rushingTier";

type LockoutInfo = { start: number; end: number; tier: number };

// Returns data about the current lockout. `end` is 0 (i.e., the distant past)
// if there is no lockout.
export const getLockout: () => LockoutInfo = () => {
  return JSON.parse(
    getStorage().getItem(lockoutKey) || '{"start": 0, "end": 0, "tier": 0}',
  );
};

// Stores data about the current lockout.
const setLockout = (lockout: LockoutInfo) => {
  getStorage().setItem(lockoutKey, JSON.stringify(lockout));
};

// Returns rushing information for the last 5 cards of the given course.
const getRushingWindow: (courseId: number) => boolean[] = (courseId) => {
  return JSON.parse(
    getStorage().getItem(`${rushingWindowKey}${courseId}`) || "[]",
  );
};

const setRushingWindow: (courseId: number, window: boolean[]) => void = (
  courseId,
  window,
) => {
  getStorage().setItem(
    `${rushingWindowKey}${courseId}`,
    JSON.stringify(window),
  );
};

// Returns the number of rushing lockouts that a user has had so far in the given course.
const getRushingTier = (courseId: number) => {
  return Number.parseInt(
    getStorage().getItem(`${rushingTierKey}${courseId}`) || "0",
  );
};

const setRushingTier = (courseId: number, tier: number) => {
  getStorage().setItem(`${rushingTierKey}${courseId}`, tier.toString());
};

export const detectAndHandleRushing = (
  duration: number,
  card: CardEntity,
  course: CourseEntity,
  completedCardEvents: EventEntityBase[],
) => {
  // The card is considered rushed if the course has rushing prevention enabled,
  // the card has never been completed before, and it is completed too quickly.
  const isRushedCard =
    course.preventRushing &&
    !completedCardEvents.find((e) => e.cardId === card.id) &&
    duration < computeRushingThreshold(card);

  // Update the stored information about the rushing window
  const prevWindow = getRushingWindow(course.id);
  const rushingWindow = [...prevWindow, isRushedCard].slice(-5);
  setRushingWindow(course.id, rushingWindow);

  // If the rushing threshold is met, increase the rushing tier and trigger a lockout.
  // We use a hard-coded "3 out of 5" threshold.
  const numRushed = rushingWindow.filter(Boolean).length;
  if (numRushed >= 3) {
    triggerLockout(course.id);
    return true;
  }

  return false;
};

const triggerLockout = (courseId: number) => {
  // Determine the rushing tier. It increases by one for each case of rushing.
  // It also decreases by one for every full day since the last lockout, so
  // that it eventually resets and users aren't locked out indefinitely.
  let rushingTier = getRushingTier(courseId);
  const lastLockout = getLockout();
  const daysSinceLastLockout = Math.floor(
    (Date.now() - lastLockout.start) / (24 * 60 * 60 * 1000),
  );
  rushingTier = Math.max(0, rushingTier - daysSinceLastLockout);
  setRushingTier(courseId, rushingTier + 1);

  // Also, clear the rushing window, to give the user a fresh start once the
  // lockout ends.
  setRushingWindow(courseId, []);

  setLockout({
    start: Date.now(),
    end: Date.now() + 10000 * 2 ** rushingTier,
    tier: rushingTier + 1,
  });
};

// Computes a card's rushing threshold based on the card's features. This is a
// regression model that estimates how much time a fast learner would spend on a
// card. For the rationale and model fitting, see:
// https://github.com/HealthLearn/card_based_rushing/blob/main/rushing_threshold_3.ipynb
const computeRushingThreshold = ({ interaction, body }: CardEntity) => {
  if (!interaction) {
    return 0;
  }

  const isCheckbox =
    interaction.component_type === InteractionComponentType.Checkbox;
  const isDropdown =
    interaction.component_type === InteractionComponentType.Dropdown;
  const isDropdownSingle =
    interaction.component_type === InteractionComponentType.DropdownSingle;
  const isNext = interaction.component_type === InteractionComponentType.Next;
  const isRadio = interaction.component_type === InteractionComponentType.Radio;
  const isShortAnswer =
    interaction.component_type === InteractionComponentType.ShortAnswer;

  const isUntilCorrect =
    (interaction.checkbox?.mode ||
      interaction.dropdown?.mode ||
      interaction.dropdownSingle?.mode ||
      interaction.radio?.mode) === "Until Correct";

  const numCheckboxOptions =
    interaction.checkbox?.multipleChoiceOptions.length || 0;
  const numRadioOptions = interaction.radio?.multipleChoiceOptions.length || 0;
  const numDropdownOptions = interaction.dropdown?.options.length || 0;
  const numDropdownSingleOptions =
    interaction.dropdownSingle?.options.length || 0;
  const minimumLength = interaction.shortAnswer?.minimumLength || 0;

  const numChars =
    body
      .map((component) => component.richText?.content)
      .reduce((acc, text) => acc + countChars(text), 0) +
    countChars(interaction.checkbox?.prompt) +
    countChars(interaction.dropdown?.prompt) +
    countChars(interaction.dropdownSingle?.prompt) +
    countChars(interaction.radio?.prompt) +
    countChars(interaction.shortAnswer?.prompt);

  const numImages = body.filter((component) => component.image).length;
  const numVideos = body.filter((component) => component.video).length;

  const threshold =
    3474.6 * (isCheckbox ? 1 : 0) +
    0 * (isRadio ? 1 : 0) +
    3474.6 * (isDropdown ? 1 : 0) +
    0 * (isDropdownSingle ? 1 : 0) +
    6465.6 * (isShortAnswer ? 1 : 0) +
    0 * (isNext ? 1 : 0) +
    1431.1 * (isUntilCorrect ? 1 : 0) +
    1.5 * numChars +
    526.3 * numImages +
    300.5 * numVideos +
    349.3 * numCheckboxOptions ** 2 +
    349.3 * numDropdownOptions ** 2 +
    1107.9 * numRadioOptions +
    1107.9 * numDropdownSingleOptions +
    1396.9 * minimumLength;

  // Limit the threshold to 15 seconds. If someone spends more than that time on
  // a card, they should not get flagged, no matter how complex the card is.
  return Math.min(threshold, 15000);
};

const countChars = (s: string | null | undefined) => (s || "").length;
