import { event_type } from "@/hl-common/types/api/PrismaEnums";
import type { CourseEntityWithStatus } from "@/hl-common/types/api/entities/Courses";
import type { EventEntityBase } from "@/hl-common/types/api/entities/Events";
import { ModuleAccessPattern } from "@/hl-common/types/api/entities/ModuleAccessPattern";
import type { ModuleEntity } from "@/hl-common/types/api/entities/Modules";

export enum ModuleStatus {
  Unlocked = 1,
  Locked = 2,
  Started = 3,
  Completed = 4,
  Repeatable = 5,
}

// helpers for determining accessible modules
const availableStatuses = new Set([
  ModuleStatus.Unlocked,
  ModuleStatus.Started,
]);

const isAvailable = (status: ModuleStatus) => {
  return availableStatuses.has(status);
};

// Helper function for exhaustiveness checking
function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export const getFirstAvailableModuleForCourse = (
  course: CourseEntityWithStatus,
  modules: ModuleEntity[] | undefined,
  events: EventEntityBase[],
) => {
  return (
    modules?.find((m) =>
      isAvailable(moduleStatusForCourse(course, m, events)),
    ) ?? null
  );
};

// note: you may want to also consider unsynced events when calling this
export const getRecommendedNextModuleForCourse = (
  course: CourseEntityWithStatus,
  events: EventEntityBase[],
  options: {
    userIntentModuleId?: number;
    justCompletedModuleId?: number;
  } = {},
) => {
  const { userIntentModuleId, justCompletedModuleId } = options;
  if (!course.modules) {
    return null;
  }

  // First priority: See if the 'intent' module is unlocked
  const userIntentModule = getFirstAvailableModuleForCourse(
    course,
    course.modules.filter((m) => m.id === userIntentModuleId),
    events,
  );
  if (userIntentModule) {
    return userIntentModule;
  }

  // Second priority: return the next module in sequence
  if (justCompletedModuleId) {
    const justCompletedIndex =
      course.modules.findIndex((m) => m.id === justCompletedModuleId) || -1;
    const afterJustCompleted = course.modules.slice(justCompletedIndex + 1);
    const nextInSequence = getFirstAvailableModuleForCourse(
      course,
      afterJustCompleted,
      events,
    );
    if (nextInSequence) {
      return nextInSequence;
    }
  }

  // Third priority: return the first mandatory module.
  // Don't recommend optional modules that the user has already skipped.
  return getFirstAvailableModuleForCourse(
    course,
    course.modules.filter((m) => !m.optional),
    events,
  );
};

// note: you may want to also consider unsynced events when calling this
export const moduleStatusForCourse = (
  course: CourseEntityWithStatus,
  module: ModuleEntity,
  events: EventEntityBase[],
) => {
  if (course.unmetPrerequisites?.length) {
    return ModuleStatus.Locked;
  }

  if (isCompleted(events, module.id)) {
    if (module.repeatable) {
      return ModuleStatus.Repeatable;
    }
    return ModuleStatus.Completed;
  }

  if (isStarted(events, module.id)) {
    return ModuleStatus.Started;
  }

  return lockedOrUnlocked(course, events, module.id);
};

const isCompleted = (courseEvents: EventEntityBase[], moduleId: number) => {
  return courseEvents.find(
    (event) =>
      event.moduleId === moduleId && event.type === event_type.module_complete,
  );
};

const isStarted = (courseEvents: EventEntityBase[], moduleId: number) => {
  return courseEvents.find(
    (event) =>
      event.moduleId === moduleId && event.type === event_type.module_begin,
  );
};

// lockedOrUnlocked returns the status of a module, given the course's modules,
// and any existing module events
export const lockedOrUnlocked = (
  course: CourseEntityWithStatus,
  courseEvents: EventEntityBase[],
  moduleId: number,
) => {
  if (!course.modules) {
    return ModuleStatus.Locked;
  }

  // get the index of the module in the course
  const moduleIdx = findModuleIndex(course, moduleId);

  if (moduleIdx === 0) {
    // first module always open
    return ModuleStatus.Unlocked;
  }

  // Determine the result for each access pattern.
  switch (course.moduleAccessPattern) {
    case ModuleAccessPattern.OpenAccess:
      return ModuleStatus.Unlocked;

    case ModuleAccessPattern.OpenAccessWithPreTestAndPostTest:
      if (moduleIdx === course.modules.length - 1) {
        // last module, check if completed all previous required modules
        if (
          course.modules
            .slice(0, -1)
            .every(
              (module) =>
                module.optional || isCompleted(courseEvents, module.id),
            )
        ) {
          return ModuleStatus.Unlocked;
        }
      } else {
        // middle modules, check if first module is completed
        if (isCompleted(courseEvents, course.modules[0].id)) {
          return ModuleStatus.Unlocked;
        }
      }
      return ModuleStatus.Locked;

    case ModuleAccessPattern.LinearProgression: {
      // ensure previous required module is completed
      const previousModuleID = course.modules
        .slice(0, moduleIdx) // modules before this one
        .reverse() // in reverse order
        .find((module) => !module.optional)?.id; // find first non-optional

      if (!previousModuleID || isCompleted(courseEvents, previousModuleID)) {
        return ModuleStatus.Unlocked;
      }
      return ModuleStatus.Locked;
    }
  }

  // This is unreachable. Each pattern enforces its own rules. There must be no
  // fall-through in the switch statement above.
  return assertUnreachable(course.moduleAccessPattern);
};

export const findModuleIndex = (
  course: CourseEntityWithStatus,
  moduleId: number,
) => {
  if (!course.modules) {
    return -1;
  }

  return course.modules.findIndex((module) => module.id === moduleId);
};
