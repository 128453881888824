import type { SkipCondition } from "@/hl-common/types/api/components/automations/SkipCondition";
import type { EventEntityBase } from "@/hl-common/types/api/entities/Events";

// check if the skip condition is met by the completedCardEvents
export default function shouldSkip(
  skipCondition: SkipCondition,
  completedCardEvents: EventEntityBase[],
) {
  if (!skipCondition.card?.id) {
    return false;
  }

  if (completedCardEvents.length === 0) {
    return false;
  }

  const relevantEvent = completedCardEvents.find(
    (event) => skipCondition.card && event.cardId === skipCondition.card.id,
  );

  if (!relevantEvent) {
    return false;
  }

  // detect if the previous card was skipped
  if (skipCondition.test === "wasSkipped") {
    return !!relevantEvent.skip; // true if skipped, false otherwise
  }

  // checkbox
  if (relevantEvent.answer?.checkboxIDs) {
    switch (skipCondition.test) {
      case "includes":
        return relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "excludes":
        return !relevantEvent.answer.checkboxIDs.includes(
          skipCondition.answerID,
        );
      case "equals":
        return (
          relevantEvent.answer.checkboxIDs.length === 1 &&
          relevantEvent.answer.checkboxIDs[0] === skipCondition.answerID
        );
      default:
        return false;
    }
  }

  // radio
  if (relevantEvent.answer?.radioID) {
    switch (skipCondition.test) {
      case "includes":
      case "equals":
        return relevantEvent.answer.radioID === skipCondition.answerID;
      case "excludes":
        return relevantEvent.answer.radioID !== skipCondition.answerID;
      default:
        return false;
    }
  }

  return false;
}
