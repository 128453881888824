import classNames from "classnames";

const defaultBgClass = "bg-blue-600";

export default function ProgressBar({
  width,
  bgClass = defaultBgClass,
  transition = true,
}: {
  width: number;
  bgClass?: string;
  transition?: boolean;
}) {
  return (
    <div className="h-2 flex-grow-1 bg-gray-200 rounded w-full">
      <div
        className={classNames("h-2 rounded", bgClass, {
          "transition-[width]": transition,
        })}
        style={{
          width: `${width}%`,
        }}
      />
    </div>
  );
}
