"use client";

import { event_type } from "@/hl-common/types/api/PrismaEnums";
import type { CourseEntityWithStatus } from "@/hl-common/types/api/entities/Courses";
import type {
  EventEntity,
  EventEntityBase,
  IngestEventRequest,
} from "@/hl-common/types/api/entities/Events";
import type { ModuleEntity } from "@/hl-common/types/api/entities/Modules";
import React, { useCallback, useEffect, useState } from "react";
import ContextNotInitializedError from "./ContextNotInitilizedError";
import {
  getUserIntents,
  setUserIntentCourseId,
  setUserIntentModuleId,
} from "./intents/userIntents";
import { getLocalCourseEvents } from "./localEvents";
import { getRecommendedNextModuleForCourse } from "./module/moduleStatusHelpers";

export const CourseContext = React.createContext({
  course: {} as CourseEntityWithStatus,
  courseEvents: [] as EventEntityBase[],
  addCourseEvent: (event: IngestEventRequest): void => {
    throw new ContextNotInitializedError();
  },
  recommendedNextModule: null as ModuleEntity | null,
});

export const WithCourse = ({
  children,
  course,
  events,
  userId,
}: {
  children: React.ReactNode;
  course: CourseEntityWithStatus;
  events: EventEntity[]; // server-synced events
  userId: number;
}) => {
  const [courseEvents, setCourseEvents] = useState<EventEntityBase[]>(events);

  // Merge local and server events. This happens in a useEffect to avoid hydration mismatch errors.
  // Note: Missing events could happen if an event arrives on the server just after getCourseEvents,
  // and the client deletes it from local storage while it receives the getCourseEvents data.
  // Note: Duplicate events may also possible outcome of races, but duplicates are harmless.
  useEffect(() => {
    const localEvents = getLocalCourseEvents(userId, course.id).flatMap(
      expandIngestEvent,
    );
    if (localEvents.length) {
      setCourseEvents([...events, ...localEvents]);
    }
  }, [events, userId, course.id]);

  // use for transitions at the end of a module
  const [recommendedNextModule, setRecommendedNextModule] =
    useState<ModuleEntity | null>(null);

  // stick not-yet-sync'd events into the CourseContext, so we can use them
  // e.g. to calculate the next available module, without another roundtrip to the server
  const addCourseEvent = useCallback(
    (event: IngestEventRequest) => {
      // merge new events with existing events in the context
      const allEvents = [...courseEvents, ...expandIngestEvent(event)];
      setCourseEvents(allEvents);

      const { m: userIntentModuleId, c: userIntentCourseId } = getUserIntents();

      // update recommendedNextModule if we just finished a module
      if (event.extraEvents?.moduleCompleteUuid) {
        setRecommendedNextModule(
          getRecommendedNextModuleForCourse(course, allEvents, {
            userIntentModuleId,
            justCompletedModuleId: event.moduleId,
          }),
        );

        // clear userIntentModuleId if we just finished that module
        if (userIntentModuleId === event.moduleId) {
          setUserIntentModuleId(undefined);
        }
      }

      // clear userIntentCourseId if we just finished that course
      if (
        event.extraEvents?.courseCompleteUuid &&
        userIntentCourseId === event.courseId
      ) {
        setUserIntentCourseId(undefined);
      }
    },
    [course, courseEvents],
  );

  return (
    <CourseContext.Provider
      value={{
        course,
        courseEvents,
        addCourseEvent,
        recommendedNextModule,
      }}
    >
      {children}
    </CourseContext.Provider>
  );
};

const expandIngestEvent = (event: IngestEventRequest): EventEntityBase[] => {
  const expanded: EventEntityBase[] = [event];

  // expand extraEvents into their own entries
  // as if they were fetched from the server
  if (event.extraEvents) {
    if (event.extraEvents.moduleBeginUuid) {
      expanded.push({
        uuid: event.extraEvents.moduleBeginUuid,
        courseId: event.courseId,
        moduleId: event.moduleId,
        type: event_type.module_begin,
        timestamp: event.timestamp,
      });
    }
    if (event.extraEvents.moduleCompleteUuid) {
      expanded.push({
        uuid: event.extraEvents.moduleCompleteUuid,
        courseId: event.courseId,
        moduleId: event.moduleId,
        type: event_type.module_complete,
        timestamp: event.timestamp,
      });
    }
    if (event.extraEvents.courseCompleteUuid) {
      expanded.push({
        uuid: event.extraEvents.courseCompleteUuid,
        courseId: event.courseId,
        type: event_type.course_complete,
        timestamp: event.timestamp,
      });
    }
  }

  return expanded;
};
