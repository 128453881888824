"use client";

import { type UserIntents, intentCookieName } from "./shared";

const COOKIE_EXPIRES_DAYS = 7;

const intentRegex = new RegExp(`\\b${intentCookieName}=([^;]*)`);

function getCookie(): string {
  const match = document.cookie.match(intentRegex);
  return match ? match[1] : "";
}

function setCookie(value: string) {
  const d = new Date();
  d.setDate(d.getDate() + COOKIE_EXPIRES_DAYS);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${intentCookieName}=${value};${expires};path=/;samesite=strict`;
}

export function getUserIntents(): UserIntents {
  const cookie = getCookie() || "{}";
  try {
    return JSON.parse(cookie);
  } catch {
    return {};
  }
}

function setUserIntents(intents: UserIntents) {
  // Only include defined values in the cookie to keep it compact
  const cleanIntents: UserIntents = {};
  if (intents.c) cleanIntents.c = intents.c;
  if (intents.m) cleanIntents.m = intents.m;
  setCookie(JSON.stringify(cleanIntents));
}

export function setUserIntentCourseId(courseId: number | undefined) {
  const intents = getUserIntents();
  if (courseId) {
    setUserIntents({ ...intents, c: courseId });
  } else {
    const { c, ...rest } = intents;
    setUserIntents(rest);
  }
}

export function setUserIntentModuleId(moduleId: number | undefined) {
  const intents = getUserIntents();
  if (moduleId) {
    setUserIntents({ ...intents, m: moduleId });
  } else {
    const { m, ...rest } = intents;
    setUserIntents(rest);
  }
}
